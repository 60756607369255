<template>
  <template v-for="group in MODULES_GROUPS">
    <div class="font-bold" v-text="group.name" />
    <div
      v-for="definition in MODULES.filter((mod) =>
        group.modules.includes(mod.key)
      )"
      class="border"
      :class="{ 'border-primary-400': modules.has(definition.key) }"
    >
      <button
        type="button"
        class="p-2 w-full hover:bg-gray-100 cursor-pointer items-center flex gap-2"
        @click="toggleModule(definition.key)"
      >
        <div class="flex items-center justify-center w-10 h-10">
          <i
            class="material-symbols-outlined text-gray-400"
            :class="{ 'text-primary-500': modules.has(definition.key) }"
            aria-hidden="true"
            v-text="definition.icon"
          />
        </div>
        <div class="w-full text-left">
          <p v-text="definition.name" />
          <p
            v-if="definition.description"
            class="text-gray-600 text-xs"
            v-text="definition.description"
          />
        </div>
      </button>
      <div
        v-if="modules.has(definition.key)"
        class="flex flex-col ml-2"
      >
        <div v-for="variant in getModuleVariants(definition.key)">
          <button
            v-if="getModuleVariants(definition.key).length > 1"
            type="button"
            class="flex items-center cursor-pointer h-8 text-left"
            @click="setModuleVariant(definition.key, variant.key)"
          >
            <i
              class="material-symbols-outlined p-2 text-[1.25rem]"
              aria-hidden="true"
              v-text="
                modules.get(definition.key)?.variant === variant.key
                  ? 'check_box'
                  : 'check_box_outline_blank'
              "
            />
            <span class="text-xs" v-html="variant.name" />
          </button>
          <template
            v-if="modules.get(definition.key)?.variant === variant.key"
          >
            <div v-for="option in variant.options" class="ml-10">
              <template v-if="option.type === 'checkbox'">
                <button
                  type="button"
                  class="flex items-center cursor-pointer h-8"
                  :title="option.description"
                  @click="
                    setModuleVariantOption(
                      definition.key,
                      option.key,
                      !getModuleVariantOption(
                        definition.key,
                        option.key,
                        false
                      )
                    )
                  "
                >
                  <i
                    class="material-symbols-outlined p-2 text-[1.25rem]"
                    aria-hidden="true"
                    v-text="
                      getModuleVariantOption(
                        definition.key,
                        option.key,
                        false
                      )
                        ? 'check_box'
                        : 'check_box_outline_blank'
                    "
                  />
                  <span class="text-xs" v-text="option.name" />
                  <i
                    v-if="option.description"
                    class="material-symbols-outlined text-sm ml-2"
                    aria-hidden="true"
                    >info</i
                  >
                </button>
              </template>
              <template v-else-if="option.type === 'file'">
                <div class="flex items-center cursor-pointer h-8">
                  <label class="cursor-pointer flex items-center">
                    <i
                      class="material-symbols-outlined p-2 text-[1.25rem]"
                      aria-hidden="true"
                      >upload_file</i
                    >
                    <span
                      class="text-xs"
                      v-text="
                        `${option.name}${
                          getModuleVariantOption<File[]>(
                            definition.key,
                            option.key,
                            []
                          )
                            ? ` (${
                                getModuleVariantOption<File[]>(
                                  definition.key,
                                  option.key,
                                  []
                                ).length
                              } selected)`
                            : ''
                        }`
                      "
                    />
                    <input
                      type="file"
                      multiple
                      accept=".docx"
                      class="hidden"
                      @change="
                        setModuleVariantOption(
                          definition.key,
                          option.key,
                          Array.from(
                            ($event.target as HTMLInputElement).files ?? []
                          )
                        )
                      "
                    />
                  </label>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue';

const props = defineProps<{
  restrictions: Record<string, boolean>
}>()

const emit = defineEmits<{
  validation: [error?: string]
}>()

type ModuleKey = 'detect_attributes' | 'detect_conditions' | 'combine_documents'

type ModuleVariantOption = {
  key:
    | 'replace_occurences_without_brackets'
    | 'transform_attributes'
    | 'detect_external_placeholders'
    | 'keep_footnotes'
    | 'files'
  type: 'checkbox' | 'file'
  name: string
  description: string
  default: unknown
}

type ModuleVariant = {
  key: 'default' | 'open_ai' | 'avvoka'
  name: string
  options: ModuleVariantOption[]
}

type Module = {
  key: ModuleKey
  name: string
  icon: string
  description: string
  variants: ModuleVariant[]
}

const modules = defineModel<Map<ModuleKey, { variant: ModuleVariant['key'], options: Record<ModuleVariantOption['key'], unknown> }>>({ required: true })

const MODULES_GROUPS: { name: string; modules: ModuleKey[] }[] = [
  {
    name: 'Detect automation',
    modules: ['detect_attributes', 'detect_conditions']
  },
  {
    name: 'Template management',
    modules: ['combine_documents']
  }
]

const MODULES: Module[] = [
  {
    key: 'detect_attributes',
    name: 'Detect placeholders',
    description: '',
    icon: 'text_fields',
    variants: [
      {
        key: 'default',
        name: 'From a precedent template (square bracketed)',
        options: [
          {
            key: 'replace_occurences_without_brackets',
            type: 'checkbox',
            name: 'Advanced placeholder detection',
            description:
              'Detect placeholders that exist outside of square brackets',
            default: false
          },
          {
            key: 'transform_attributes',
            type: 'checkbox',
            name: 'Transformation detection',
            description: 'Transform placeholders if they are in upper-case',
            default: true
          },
          {
            key: 'detect_external_placeholders',
            type: 'checkbox',
            name: 'E-signature tag detection',
            description: 'Detect tags that may be used by Docusign or other esignature platforms',
            default: false
          }
        ]
      },
      {
        key: 'open_ai',
        name: 'From an existing document <sup>AI✦</sup>',
        options: [
          {
            key: 'detect_external_placeholders',
            type: 'checkbox',
            name: 'E-signature tag detection',
            description: 'Detect tags that may be used by Docusign or other esignature platforms',
            default: false
          }
        ]
      }
    ]
  },
  {
    key: 'detect_conditions',
    name: 'Conditions',
    icon: 'equal',
    description: '',
    variants: [
      {
        key: 'default',
        name: 'From a precedent template (square bracketed followed by a footnote)',
        options: [
          {
            key: 'keep_footnotes',
            type: 'checkbox',
            name: 'Do not remove footnotes from detected conditions',
            description: '',
            default: false
          }
        ]
      },
      {
        key: 'avvoka',
        name: 'From a precedent template (with Avvoka mark-up)',
        options: []
      }
    ]
  },
  {
    key: 'combine_documents',
    name: 'Combine documents',
    icon: 'cell_merge',
    description: 'Combines one or more documents together',
    variants: [
      {
        key: 'default',
        name: '',
        options: [
          {
            key: 'files',
            type: 'file',
            name: 'Select at least one document to merge with your existing template',
            description: '',
            default: []
          }
        ]
      }
    ]
  }
] as const

const validationMessage = computed(() => {
  const mds = modules.value
  if (mds.size === 0) {
    return 'You need to select at least one module'
  }

  for (const [key, config] of mds.entries()) {
    const variant = findModule(key).variants.find(
      (variant) => variant.key === config.variant
    ) as ModuleVariant

    for (const option of variant.options) {
      const value = config.options[option.key]
      const error = OPTION_VALIDATORS[option.type](value)

      if (error) {
        return error
      }
    }
  }
})

watch(validationMessage, (value) => {
  emit('validation', value)
})

const findModule = (key: ModuleKey) =>
  MODULES.find((definition) => definition.key === key) as Module

const findModuleVariant = (key: ModuleKey, variantKey: ModuleVariant['key']) =>
  findModule(key).variants.find(
    (variant) => variant.key === variantKey
  ) as ModuleVariant

const OPTION_VALIDATORS: Record<
  ModuleVariantOption['type'],
  (value: unknown) => string | undefined
> = {
  file: (value) => {
    if (Array.isArray(value) && value.length > 0) return
    else return 'You need to upload at least one document'
  },
  checkbox: () => undefined
}

const getModuleVariants = (key: ModuleKey) => {
  return findModule(key).variants.filter((variant) => {
    const str = `${key}.${variant.key}`
    return !(str in props.restrictions && !props.restrictions[str])
  })
}

// Enables & disables a module, will default all options and select the first variant
const toggleModule = (key: ModuleKey) => {
  const mds = modules.value
  if (mds.has(key)) {
    mds.delete(key)
  } else {
    setModuleVariant(key, findModule(key).variants[0].key)
  }
}

const setModuleVariant = (key: ModuleKey, variantKey: ModuleVariant['key']) => {
  const variant = findModuleVariant(key, variantKey)

  const options = {} as Record<ModuleVariantOption['key'], unknown>
  if (variant.options) {
    for (const option of variant.options) {
      options[option.key] = option.default
    }
  }

  const mds = modules.value
  mds.set(key, {
    variant: variant.key,
    options
  })
}

const setModuleVariantOption = (
  key: ModuleKey,
  optionKey: ModuleVariantOption['key'],
  value: unknown
) => {
  const mds = modules.value
  const mod = mds.get(key)
  if (mod) {
    mod.options[optionKey] = value

    mds.set(key, mod)
  }
}

const getModuleVariantOption = <T,>(
  key: ModuleKey,
  optionKey: ModuleVariantOption['key'],
  defaultValue: T
) => {
  return (modules.value.get(key)?.options?.[optionKey] ?? defaultValue) as T
}
</script>