<template>
  <GlobalDialog
    class="square flex flex-col items-center bg-white !p-4 rounded-md w-[800px]"
  >
    <h3
      class="font-bold"
      v-text="props.formId ? localize('title.edit') : localize('title.create')"
    />
    <div
      class="flex flex-col gap-3 w-full max-h-[70vh] p-1 pr-2 overflow-y-auto"
      :class="{ 'pointer-events-none opacity-75 transition-opacity': !isReady }"
    >
      <div>
        <label for="form_input_title" v-html="getLabelHtml('title')" />
        <input
          id="form_input_title"
          v-model="formTitle"
          type="text"
          class="avv-input-tw"
          :placeholder="localize('form.title')"
          :disabled="getDisabledState('title')"
        />
      </div>
      <b class="mt-3" v-text="localize('category.general')" />
      <div>
        <label for="form_input_template_id">
          <span v-html="getLabelHtml('template_id')" />
          <i
            class="material-symbols-outlined text-sm ml-2 opacity-75"
            aria-hidden="true"
            :title="localize('form.template_id_info')"
            >info</i
          >
        </label>
        <AvvSelect
          id="form_input_template_id"
          class="avv-input-tw no-radius"
          :value="formTemplateId"
          :search="true"
          :placeholder="localize('form.template_id_placeholder')"
          :frozen="getDisabledState('template_id')"
          @select="(option) => (formTemplateId = option.getAttribute('value'))"
        >
          <div class="menu !max-h-[350px]">
            <div class="z-10">
              <input
                v-model="formTemplateSearch"
                type="search"
                class="avv-input w-full"
              />
            </div>
            <avv-option
              v-for="template in formTemplateValues"
              :key="template.id"
              :value="template.id"
              v-text="`${template.name} (#${template.id})`"
            />
          </div>
        </AvvSelect>
      </div>
      <div>
        <label for="form_input_party_id" v-html="getLabelHtml('party_id')" />
        <AvvSelect
          id="form_input_party_id"
          :value="formPartyId"
          class="avv-input-tw no-radius"
          :placeholder="localize('form.party_id_placeholder')"
          :frozen="getDisabledState('party_id')"
          @select="(option) => (formPartyId = option.getAttribute('value'))"
        >
          <div class="menu !max-h-[350px]">
            <avv-option
              v-for="party in formTemplate.parties ?? []"
              :key="party"
              :value="party"
              v-text="party"
            />
          </div>
        </AvvSelect>
      </div>
      <div>
        <label for="form_input_user_id" v-html="getLabelHtml('user_id')" />
        <AvvSelect
          id="form_input_user_id"
          :value="formUserId"
          :search="true"
          class="avv-input-tw no-radius"
          :placeholder="localize('form.user_id_placeholder')"
          :frozen="getDisabledState('user_id')"
          @select="(option) => (formUserId = option.getAttribute('value'))"
        >
          <div class="menu !max-h-[350px]">
            <div class="z-10">
              <input
                v-model="formUserSearch"
                type="search"
                class="avv-input w-full"
              />
            </div>
            <avv-option
              v-for="user in formUserValues"
              :key="user.id"
              :value="user.id"
              v-text="user.email"
            />
          </div>
        </AvvSelect>
      </div>
      <b class="mt-3" v-text="localize('category.submissions')" />
      <div v-if="formResponseModeValues.length > 1">
        <label
          for="form_input_response_mode"
          v-html="getLabelHtml('response_mode')"
        />
        <select
          id="form_input_response_mode"
          v-model="formResponseMode"
          type="text"
          class="avv-input-tw"
          :placeholder="localize('form.response_mode')"
          :disabled="getDisabledState('response_mode')"
        >
          <option
            v-for="value in formResponseModeValues"
            :key="value"
            :value="value"
            v-text="localize(`form.response_modes.${value}`)"
          />
        </select>
      </div>
      <template v-if="formResponseMode === 'generate_via_url' && formUrl">
        <div class="flex mx-2">
          {{ localize('form.url') }}:
          <div class="italic ml-2" v-text="formUrl" />
          <button
            type="button"
            class="flex items-center text-primary-500"
            :title="localize('copy_url')"
            @click="copyField('url')"
          >
            <i class="material-symbols-outlined px-2" aria-hidden="true"
              >content_copy</i
            >
          </button>
        </div>
      </template>
      <template
        v-else-if="formResponseMode === 'generate_via_request' && formId"
      >
        <div class="flex mx-2">
          {{ localize('form.id') }}:
          <div class="italic ml-2" v-text="formId" />
          <button
            type="button"
            class="flex items-center text-primary-500"
            :title="localize('copy_id')"
            @click="copyField('id')"
          >
            <i class="material-symbols-outlined px-2" aria-hidden="true"
              >content_copy</i
            >
          </button>
        </div>
      </template>
      <div v-if="formResponseMode === 'generate_via_url'">
        <label>
          <input
            v-model="formSubmitAnonymous"
            type="checkbox"
            class="avv-checkbox"
            :disabled="getDisabledState('submit_anonymous')"
          />
          {{ localize('form.submit_anonymous') }}
        </label>
      </div>
      <div>
        <label
          for="form_input_expires_in_minutes"
          v-html="getLabelHtml('expires_in_minutes')"
        />
        <input
          id="form_input_expires_in_minutes"
          v-model="formExpiresInMinutes"
          type="number"
          class="avv-input-tw"
          :placeholder="localize('form.expires_in_minutes')"
          :disabled="getDisabledState('expires_in_minutes')"
        />
      </div>
      <template v-if="formResponseMode === 'generate_via_url'">
        <div>
          <label
            for="form_input_submit_limit"
            v-html="getLabelHtml('submit_limit')"
          />
          <select
            id="form_input_submit_limit"
            v-model="formSubmitLimit"
            type="text"
            class="avv-input-tw"
            :placeholder="localize('form.submit_limit')"
            :disabled="getDisabledState('submit_limit')"
          >
            <option
              v-for="value in [50, 100, 200, 300]"
              :key="value"
              :value="value"
              v-text="value"
            />
          </select>
        </div>
        <div>
          <label>
            <input
              v-model="formSubmitLimitMessageToggle"
              type="checkbox"
              class="avv-checkbox"
              :disabled="getDisabledState('submit_limit_message')"
            />
            {{ localize('form.submit_limit_message_toggle') }}
          </label>
        </div>
        <div v-if="formSubmitLimitMessageToggle">
          <label
            for="form_input_submit_limit_message"
            v-html="getLabelHtml('submit_limit_message')"
          />
          <textarea
            id="form_input_submit_limit_message"
            v-model="formSubmitLimitMessage"
            class="avv-input-tw"
            :placeholder="localize('form.submit_limit_message')"
            :disabled="getDisabledState('submit_limit_message')"
          />
        </div>
      </template>
      <b class="mt-3" v-text="localize('category.appearance')" />
      <div>
        <label>
          <input
            v-model="formDisplayTitle"
            type="checkbox"
            class="avv-checkbox"
            :disabled="getDisabledState('display_title')"
          />
          {{ localize('form.display_title') }}
        </label>
      </div>
      <div>
        <label>
          <input
            v-model="formDisplayLogo"
            type="checkbox"
            class="avv-checkbox"
            :disabled="getDisabledState('display_logo')"
          />
          {{ localize('form.display_logo') }}
        </label>
      </div>
      <div v-if="formResponseMode !== 'generate_via_url'">
        <label
          for="form_input_csp_frame_ancestors"
          v-html="getLabelHtml('csp_frame_ancestors')"
        />
        <textarea
          id="form_input_csp_frame_ancestors"
          v-model="formCSPFrameAncestors"
          class="avv-input-tw"
          rows="2"
          :placeholder="localize('form.csp_frame_ancestors_placeholder')"
          :disabled="getDisabledState('csp_frame_ancestors')"
        />
      </div>
      <b class="mt-3" v-text="localize('category.after')" />
      <div>
        <label>
          <input
            v-model="formSubmitMessageToggle"
            type="checkbox"
            class="avv-checkbox"
            :disabled="getDisabledState('submit_message')"
          />
          {{ localize('form.submit_message_toggle') }}
        </label>
      </div>
      <div v-if="formSubmitMessageToggle">
        <label
          for="form_input_submit_message"
          v-html="getLabelHtml('submit_message')"
        />
        <textarea
          id="form_input_submit_message"
          v-model="formSubmitMessage"
          class="avv-input-tw"
          :placeholder="localize('form.submit_message')"
          :disabled="getDisabledState('submit_message')"
        />
      </div>
      <div>
        <label>
          <input
            v-model="formAcceptChanges"
            type="checkbox"
            class="avv-checkbox"
            :disabled="getDisabledState('accept_changes')"
          />
          {{ localize('form.accept_changes') }}
        </label>
      </div>
      <div>
        <label>
          <input
            v-model="formSubmitExport"
            type="checkbox"
            class="avv-checkbox"
            :disabled="getDisabledState('submit_export')"
          />
          {{ localize('form.submit_export') }}
        </label>
      </div>
      <div>
        <label
          for="form_input_submit_redirect_url"
          v-html="getLabelHtml('submit_redirect_url')"
        />
        <input
          id="form_input_submit_redirect_url"
          v-model="formSubmitRedirectUrl"
          type="text"
          class="avv-input-tw"
          :placeholder="localize('form.submit_redirect_url_placeholder')"
          :disabled="getDisabledState('submit_redirect_url')"
        />
      </div>
    </div>
    <div class="flex flex-row gap-4 mt-4 justify-end w-full">
      <button
        class="max-w-48 enabled:cursor-pointer w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm mt-3 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 sm:mt-0"
        @click="close"
        v-text="localize('cancel')"
      />
      <button
        v-if="props.edit"
        class="max-w-48 enabled:cursor-pointer w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 sm:col-start-2"
        :disabled="!isReady || !isValid"
        @click="save"
        v-text="localize('save')"
      />
    </div>
  </GlobalDialog>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, type UnwrapRef, watch } from 'vue'
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import FormsApi from '@api/FormsApi'
import { useOrganisationStore } from '@stores/generic/organisation.store'
import { getActivePinia } from 'pinia'
import Utils from '../utils'

type BasicForm = Pick<
  Backend.Models.Form,
  | 'id'
  | 'accept_changes'
  | 'title'
  | 'csp_frame_ancestors'
  | 'party_id'
  | 'user_id'
  | 'display_logo'
  | 'display_title'
  | 'expires_in_minutes'
  | 'submit_redirect_url'
  | 'template_id'
  | 'response_mode'
  | 'submit_message'
  | 'submit_export'
  | 'submit_limit'
  | 'submit_limit_message'
  | 'submit_anonymous'
> & {
  url?: string
}

const AvvSelect = window.AvvSelect

const store = useOrganisationStore(getActivePinia())

const formResponseModeDefault = computed(
  () => formResponseModeValues.value[formResponseModeValues.value.length - 1]
)
const formResponseModeValues = computed(() => {
  if (store.flippers.forms_generate_via_link) {
    return ['generate_via_request', 'generate_via_url'] as const
  } else {
    return ['generate_via_request'] as const
  }
})

const props = withDefaults(
  defineProps<{
    edit?: false
    formId?: string
  }>(),
  {
    edit: false
  }
)

// Store and fetch form after opening
const form = ref<BasicForm>()

const data = ref<{
  templates: {
    id: number
    name: string
    user_id: number
    // Optional, hydrated later
    parties?: string[]
    users?: {
      id: number
      email: string
    }[]
  }[]
}>({
  templates: []
})

if (props.formId) {
  onMounted(async () => {
    const { form: _form, hydrate } = await FormsApi.get<{
      form: BasicForm
      hydrate: UnwrapRef<typeof data>
    }>({ id: props.formId })

    form.value = _form
    data.value = hydrate
  })
} else {
  form.value = {
    id: 0,
    title: '',
    accept_changes: true,
    csp_frame_ancestors: [],
    party_id: '',
    submit_anonymous: false,
    user_id: 0,
    display_logo: true,
    display_title: true,
    response_mode: formResponseModeDefault.value,
    submit_message: null,
    submit_limit: 50,
    submit_limit_message: null,
    template_id: null,
    expires_in_minutes: 10080,
    submit_redirect_url: '',
    submit_export: true
  }

  onMounted(async () => {
    data.value = await FormsApi.hydrate()
  })
}

const getDisabledState = (field: keyof BasicForm): boolean | undefined => {
  if (!props.edit || !form.value) return true
  switch (field) {
    case 'title':
    case 'display_logo':
    case 'display_title':
    case 'submit_limit':
    case 'submit_limit_message':
    case 'submit_message':
    case 'accept_changes':
    case 'submit_export':
    case 'csp_frame_ancestors':
    case 'submit_redirect_url':
      return undefined
    case 'submit_anonymous':
    case 'template_id':
    case 'party_id':
    case 'user_id':
    case 'expires_in_minutes':
    case 'response_mode':
      return props.formId ? true : undefined
    default:
      return true
  }
}

const getLabelHtml = (field: keyof BasicForm): string => {
  return `${localize(`form.${field}`)}${isRequired(field) ? ` <sup class="text-red-500">*</sup>` : ''}`
}

const requiredFields = computed<(keyof BasicForm)[]>(() => {
  if (formResponseMode.value === 'generate_via_url') {
    return [
      'title',
      'template_id',
      'party_id',
      'user_id',
      'submit_redirect_url',
      'expires_in_minutes',
      'response_mode',
      'submit_limit'
    ]
  } else {
    return [
      'title',
      'template_id',
      'party_id',
      'user_id',
      'submit_redirect_url',
      'expires_in_minutes',
      'response_mode'
    ]
  }
})

const isRequired = (field: keyof BasicForm): boolean => {
  return requiredFields.value.includes(field)
}

const isValid = computed(() => {
  const allFieldsPresent = requiredFields.value.every((f) => form.value?.[f])

  return allFieldsPresent && Utils.isURLValid(form.value?.submit_redirect_url)
})
const isReady = computed(() => !!form.value)

const useProperty = <T extends keyof BasicForm, U = BasicForm[T]>(
  field: T,
  def: BasicForm[T],
  mapper?: { get: (value: BasicForm[T]) => U; set: (value: U) => BasicForm[T] }
) => {
  const internalComputed = computed({
    get: () => form.value?.[field] ?? def,
    set: (value) => {
      if (form.value) {
        form.value[field] = value
      }
    }
  })
  if (mapper) {
    return computed({
      get: () => mapper.get(internalComputed.value),
      set: (value) => (internalComputed.value = mapper.set(value))
    })
  } else {
    return internalComputed
  }
}

const useToggleProperty = <
  T extends keyof Pick<BasicForm, 'submit_limit_message' | 'submit_message'>
>(
  field: T,
  def: BasicForm[T]
) =>
  computed({
    get: () => form.value?.[field] !== null,
    set: (value) => {
      if (!form.value) return
      form.value[field] = value ? def : null
    }
  })

// Properties
const formTitle = useProperty('title', '')
const formDisplayTitle = useProperty('display_title', true)
const formDisplayLogo = useProperty('display_logo', true)
const formAcceptChanges = useProperty('accept_changes', true)
const formResponseMode = useProperty(
  'response_mode',
  formResponseModeDefault.value
)
const formSubmitMessage = useProperty('submit_message', null)
const formSubmitLimit = useProperty('submit_limit', 50)
const formSubmitLimitMessage = useProperty('submit_limit_message', null)
const formSubmitExport = useProperty('submit_export', true)
const formSubmitAnonymous = useProperty('submit_anonymous', false)
const formPartyId = useProperty('party_id', '')
const formExpiresInMinutes = useProperty('expires_in_minutes', 10080)
const formSubmitRedirectUrl = useProperty('submit_redirect_url', '')
const formCSPFrameAncestors = useProperty('csp_frame_ancestors', [], {
  get: (value) => value?.join(',') ?? '',
  set: (value) =>
    value
      .split(',')
      .map((v) => v.trim())
      .filter((v) => v)
})
const formTemplateId = useProperty('template_id', null)
const formTemplate = computed(
  () =>
    data.value.templates.find((t) => t.id == formTemplateId.value) ??
    ({
      id: -1,
      name: '',
      user_id: 0
    } satisfies (typeof data.value.templates)[number])
)
const formUserId = useProperty('user_id', 0)
const formUrl = useProperty('url', undefined)
const formId = useProperty('id', 0)

watch(formTemplateId, async () => {
  // Hydrate users & parties
  if (!formTemplate.value.users || !formTemplate.value.parties) {
    const { users, parties } = await FormsApi.hydrate<{
      users: { id: number; email: string }[]
      parties: string[]
    }>({ query: { template_id: formTemplate.value.id } })

    formTemplate.value.users = users
    formTemplate.value.parties = parties
  }

  if (!formTemplate.value.parties.includes(formPartyId.value)) {
    formPartyId.value = ''
  }

  if (!formTemplate.value.users.some((u) => u.id == formUserId.value)) {
    formUserId.value = 0
  }
})

const formTemplateSearch = ref('')
const formTemplateValues = computed(() => {
  const term = formTemplateSearch.value.trim().toLowerCase()

  if (term) {
    return data.value.templates.filter((t) =>
      t.name.toLowerCase().includes(term)
    )
  } else {
    return data.value.templates
  }
})

const formUserSearch = ref('')
const formUserValues = computed(() => {
  const term = formUserSearch.value.trim().toLowerCase()
  const arry = formTemplate.value.users ?? []

  if (term) {
    return arry.filter((t) => t.email.toLowerCase().includes(term))
  } else {
    return arry
  }
})

// Toggles, set value to empty string or null
const formSubmitLimitMessageToggle = useToggleProperty(
  'submit_limit_message',
  ''
)
const formSubmitMessageToggle = useToggleProperty('submit_message', '')

const emit = defineEmits<{
  (e: 'callback', reload: boolean): void
}>()

const localize = (key: string, args?: any) =>
  window.localizeText(`forms.form_dialog.${key}`, args)

const close = () => emit('callback', false)

const save = async () => {
  try {
    if (props.formId) {
      // Update existing
      await FormsApi.update({
        params: { id: props.formId },
        data: {
          form: form.value
        }
      })
    } else {
      // Save new one
      await FormsApi.create({
        data: {
          form: form.value
        }
      })
    }

    window.avv_toast_later({
      type: 'success',
      message: localize('save_success')
    })

    emit('callback', true)
  } catch (e) {
    window.avv_toast({
      type: 'error',
      message: localize('save_error', {
        errors: e?.response?.data?.errors?.join(', ')
      })
    })
  }
}

const copyField = (field: keyof BasicForm) => {
  Utils.copyText(String(form.value?.[field]))

  window.avv_toast({
    message: localize(`copy_${field}_success`)
  })
}
</script>

<style lang="scss" scoped>
select.avv-input-tw[disabled] {
  cursor: not-allowed;
  opacity: 50%;
}
</style>
<style lang="scss">
avv-select[frozen='true'] {
  cursor: not-allowed;
  opacity: 50%;
}
</style>
